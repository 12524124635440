// extracted by mini-css-extract-plugin
export var mainImage = "PortfolioPage-module--mainImage--1BoeD";
export var mainTitle = "PortfolioPage-module--mainTitle--3Pzvn";
export var content = "PortfolioPage-module--content--3aBSJ";
export var content__paragraphs = "PortfolioPage-module--content__paragraphs--1pRA6";
export var content__paragraphs__item = "PortfolioPage-module--content__paragraphs__item--2NVAp";
export var content__info = "PortfolioPage-module--content__info--2C_QI";
export var content__info__item = "PortfolioPage-module--content__info__item--VG5Y4";
export var content__info__item__title = "PortfolioPage-module--content__info__item__title--3tUIm";
export var content__info__item__description = "PortfolioPage-module--content__info__item__description--e06WK";
export var linkText = "PortfolioPage-module--linkText--uYQ1N";