// extracted by mini-css-extract-plugin
export var mainWrap = "FlipCard-module--mainWrap--Jc0Nq";
export var header = "FlipCard-module--header--2TtsS";
export var header__text = "FlipCard-module--header__text--mUtkg";
export var header__title = "FlipCard-module--header__title--1nnAi";
export var wrapper = "FlipCard-module--wrapper--3zyiv";
export var wrapper__button = "FlipCard-module--wrapper__button--nh0EO";
export var wrapper__card = "FlipCard-module--wrapper__card--boQTd";
export var wrapper__back = "FlipCard-module--wrapper__back--2m8uD";
export var wrapper__card__line = "FlipCard-module--wrapper__card__line--cy8Tj";
export var wrapper__card__sideTitle = "FlipCard-module--wrapper__card__sideTitle--3TVPE";
export var topRight = "FlipCard-module--topRight--3uTS-";
export var botLeft = "FlipCard-module--botLeft--3d6Md";
export var topLeft = "FlipCard-module--topLeft--J7s_2";
export var botRight = "FlipCard-module--botRight--3MyTx";
export var wrapper__card__sideTitle__title = "FlipCard-module--wrapper__card__sideTitle__title--2lxQK";
export var wrapper__front__title = "FlipCard-module--wrapper__front__title--2ZAg6";
export var wrapper__front = "FlipCard-module--wrapper__front--wTavd";
export var wrapper__back_flipped = "FlipCard-module--wrapper__back_flipped--1xN0D";
export var wrapper__back__text = "FlipCard-module--wrapper__back__text--iez-Q";
export var wrapper__front_flipped = "FlipCard-module--wrapper__front_flipped--2eZM0";
export var iconMobMirror = "FlipCard-module--iconMobMirror--3iF7e";