// extracted by mini-css-extract-plugin
export var modal = "Modal-module--modal--1uszs";
export var modal__background = "Modal-module--modal__background--3UsYW";
export var modal__content = "Modal-module--modal__content--3Vy1y";
export var fadeSlide = "Modal-module--fadeSlide--2QHOY";
export var modal__content__container = "Modal-module--modal__content__container--2kiLn";
export var modal__content__container__inner = "Modal-module--modal__content__container__inner--1X5sr";
export var modal__content__container__inner__titles__title = "Modal-module--modal__content__container__inner__titles__title--hRJAG";
export var subtitle = "Modal-module--subtitle--1FQhF";
export var modal__content__container__inner__children = "Modal-module--modal__content__container__inner__children--GlSTJ";
export var modal__content__close = "Modal-module--modal__content__close--kraY7";
export var modal__content__titles__title = "Modal-module--modal__content__titles__title--3g8YY";