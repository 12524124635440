// extracted by mini-css-extract-plugin
export var container = "Search-module--container--qb1gY";
export var active = "Search-module--active--238YW";
export var container__button = "Search-module--container__button--1p4VF";
export var hidden = "Search-module--hidden--1LNAl";
export var container__button__icon = "Search-module--container__button__icon--frVFB";
export var container__form = "Search-module--container__form--1_ffs";
export var widthAnimation = "Search-module--widthAnimation--1ietb";
export var container__form__input = "Search-module--container__form__input--2TzNi";
export var container__animatedMask = "Search-module--container__animatedMask--3DUHu";
export var maskAnimation = "Search-module--maskAnimation--2VMn_";