// extracted by mini-css-extract-plugin
export var content = "TextWithCubes-module--content--2vIX-";
export var iconTop = "TextWithCubes-module--iconTop--3MWpK";
export var iconMiddle = "TextWithCubes-module--iconMiddle--1iPxP";
export var iconBottom = "TextWithCubes-module--iconBottom--1Y0PN";
export var animate1 = "TextWithCubes-module--animate1--3nXLw";
export var anime1 = "TextWithCubes-module--anime1--w9lF9";
export var animate2 = "TextWithCubes-module--animate2--26aH_";
export var anime2 = "TextWithCubes-module--anime2--2YY66";
export var animate3 = "TextWithCubes-module--animate3--1vJuI";
export var anime3 = "TextWithCubes-module--anime3--1xpew";