// extracted by mini-css-extract-plugin
export var wrapper = "Card-module--wrapper--yEKZb";
export var wrapper_fixed = "Card-module--wrapper_fixed--2hlfF";
export var wrapper__buttonWrap = "Card-module--wrapper__buttonWrap--2NXr1";
export var card = "Card-module--card--2FMRh";
export var card__header = "Card-module--card__header--2T0l9";
export var card__header__icon = "Card-module--card__header__icon--2FXq3";
export var card__header__par = "Card-module--card__header__par--2Dlwj";
export var visible = "Card-module--visible--3JT1T";
export var shaking = "Card-module--shaking--NJi5w";
export var shake = "Card-module--shake--2DQM5";
export var fixed = "Card-module--fixed--36524";
export var move = "Card-module--move--C39B_";